@import 'web/bootstrap/scss/bootstrap.scss';

@import url('https://fonts.googleapis.com/css?family=Lato:400,900');

/*background-color: rgb(205, 171, 158);*/
/*$color1: #3cdbc0;*/
$color1: #0de8e3;
$link-color: #666666;
$link-color-hover: #00E2D9;
$menu-border-color: #c7c7cc;
$background-color: #ffffff;

body {
	font-family: 'Lato', sans-serif;
	/*background-color: #f5f5f5;*/
	background-color: #ffffff;
}

a {
	color: $link-color;
	text-decoration: none;
}

a:hover {
	color: $link-color-hover;
	text-decoration: none;
}

p {
	font-size: 16px;
  line-height: 1.79;
  color: #444444;
}

#container-1140 {
    max-width: 1140px !important;
}
#container-1440 {
    max-width: 1440px !important;
}

/*MENU RESPONSIVE*/
.collapse.show {
    background: white;
    z-index: 101;
}
.btn-group-vertical>.btn-group:after, .btn-group-vertical>.btn-group:before, .btn-toolbar:after, 
.btn-toolbar:before, .container-fluid:after, .container-fluid:before, .container:after, .container:before, 
.dl-horizontal dd:after, .dl-horizontal dd:before, .form-horizontal .form-group:after, .form-horizontal .form-group:before, 
.modal-footer:after, .modal-footer:before, .nav:after, .nav:before, .navbar-collapse:after, .navbar-collapse:before,
.navbar-header:after, .navbar-header:before, .navbar:after, .navbar:before, .pager:after, .pager:before, 
.panel-body:after, .panel-body:before, .row:after, .row:before, .vc_row:after, .vc_row:before {
  content: normal !important;
}
#main-menu-container .navbar .navbar-nav {
    background: white;
    z-index: 101;
}
#main-menu-container .navbar .active {
  height: auto !important;
}
.current_page_item, .current-menu-item {
  font-weight: 600;
}

.text-content-page {
  text-align: justify;
}

/*GLOBAL*/
.col-no-padding {
  padding-left: 0px;
  padding-right: 0px;
}
.col-padding-top-40 {
  padding-top: 10px;
}
.col-padding-top-30 {
  padding-top: 10px;
}
.col-padding-top-20 {
  padding-top: 10px;
}
.col-margin-top-80, .col-margin-top-100 {
  margin-top: 20px;
}
.col-margin-top-60, .col-margin-top-40, .col-margin-top-30, .col-margin-top-20, .col-margin-top-10 {
  margin-top: 10px;
}
.col-centered {
  float: none;
  margin: 0 auto;
}
.centered {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.col-margin-bottom-40 {
  margin-bottom: 10px;
}

/*PAGE*/
.title-content-page {
  font-size: 33px;
  font-weight: 900;
  line-height: 33px;
}
.title-content-page-center {
  font-size: 24px;
  font-weight: 900;
  line-height: 33px;
  text-align: center;
}
.text-content-page {
  font-size: 18px;
  line-height: 29px;
  color: #444444;
}
.text-content-page-center {
  font-size: 18px;
  line-height: 29px;
  color: #444444;
  text-align: center;
}
.text-sidebar-page {
  font-size: 18px;
  font-weight: 900;
  line-height: 1;
  text-align: left;
  color: #111111;
}
.title-content-page-2 {
	font-size: 20px;
 	font-weight: 900;
}
.text-sidebar-page-2 {
  font-size: 34px;
  line-height: 33px;
  text-align: center;
  color: $color1;
}
.text-sidebar-page-2-center {
  font-size: 34px;
  line-height: 33px;
  text-align: center;
  color: $color1;
}
#content-categories {
  margin-top: 20px;
  text-align: right;
  font-size: 14px;
}
.categories {
  list-style: none;
}
.categories ul {
  float: right;
}
.cat-item {
  list-style: none;
  float: left;
  margin-right: 30px;
}
.taxonomy {
  list-style: none;
  float: right;
}
.taxonomy li {
  float: left;
  margin-left: 30px;
  cursor: pointer;
}
.col-padding-5 {
  padding-left: 5px;
  padding-right: 5px;
}

#content-project-img {
  padding-right: 0px;
  padding-left: 0px;
  background-size: cover;
  min-height: 200px;
  background-position: center center;
}
#content-project-img img {
  width: 100%;
  height: 100%;
}
.bg-corporative {
  background-color: $color1;
}
/*NEW HOME*/
.mt-home-title {
  margin-top: 100px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
}
.home-title-1 {
  font-weight: 900;
  color: #000;
  font-size: 30px;
}
hr.hr-home-title {
  width: 10%;
  background-color: #000;
  border: 1px solid #000;
}
p.home-text-1 {
  font-size:16px;
  font-weight: 600;
  color: #000;
}
#flecha-home {
  text-align: center;
  margin-top: 15px;
}
hr.hr-home-2 {
  width: 10%;
  background-color: #444444;
  border: 1px solid #444444;
}
.home-title-2, .home-title-4 {
  font-weight: 900;
  font-size: 33px;
}
.home-title-3, .home-title-3 a {
  font-weight: 900;
  font-size: 16px;
  color: #212629;
}
hr.hr-home-3 {
  width: 10%;
  background-color: $color1;
  border: 1px solid $color1;
}
hr.hr-home-4 {
  width: 10%;
  background-color: #212529;
  border: 1px solid #212529;
}
hr.hr-home-5 {
  width: 10%;
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.back-home-image {
  background: -webkit-gradient(linear, left top, right top, from(#0de8e3), to(#27fcc4));
  background: linear-gradient(to right, #0de8e3, #27fcc4);
  height: 250px;
  background-size: cover;
  background-position: center center;
}
p.home-text-2 {
  font-size: 22px;
  color: #ffffff;
}
.btn-home-2 {
  background: transparent;
  padding: 11px 40px 11px 40px;
  color: #ffffff;
  border: solid 2px #ffffff;
}
.btn-home-2:hover {
  color: #f8f8f8;
}
input.suscribe-input-mail  {
  border: none;
  border: 2px solid #8b909d;
  width: 100%;
  padding: 4px 0px 4px 4px;
}
.suscribe-input-submit {
  background: -webkit-gradient(linear, left top, right top, from(#0de8e3), to(#27fcc4));
  background: linear-gradient(to right, #0de8e3, #27fcc4);
  padding: 6px 12px 6px 12px;
  color: #ffffff;
  border: 0px;
  width: 100%;
  margin-top: 15px;
}
.suscribe-input-submit:hover {
  color: #f8f8f8;
}
.home-text-2 {
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
}
.bg-home-services {
  background-color: #f8f8f8;
}
/*HOME*/
.img-home-services {
  width: 25%;
}
.home-title-6 {
  text-align: center;
  font-size: 24px;
  font-weight: 300;
}
.home-text-4 {
  font-size: 18px;
  text-align: center;
}
.img-home-trabajos {
    width: 100%;
}
.col-padding-left-5 {
  padding-left: 5px;
}
.col-padding-right-5 {
  padding-right: 5px;
}
.col-padding-top-10 {
    padding-top: 10px;
}
.home-text-5 {
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  font-weight: 300;
 line-height: 25px;
}
.content-link-rss {
  width: 35px;
  height: 35px;
  background: transparent;
  border: solid 1px #ffffff;
  border-radius: 100%;
  padding: 2px;
  margin-left:5px;
  margin-right:5px;
  float: left;
}
.link-home-rss {
  font-size:16px;
  color: #ffffff;
}
.link-home-rss:hover {
  color: #f8f8f8;
}
/*AGENCY*/
.content-back-front {
  width:100%;
  padding-left: 15px;
  padding-right: 15px;
}
.div-back {
  width: 100%;
}
.div-front {
  background-color: #ffffff;
  width: 100%;
}
.img-agencia-gugo {
  max-width: 100%;
  height: auto;
  width: 100%;
}

/*PROYECTOS*/
.title-proyectos, .text-proyectos {
  color: white;
  line-height: 1.1;
}
.title-proyectos {
  font-size: 30px;
  font-weight: 900;
}
.text-proyectos {
  font-size: 24px;
  font-weight: 800;
}
.image-icon {
  width: 22%;
  height: auto;
}
/*IMAGE HOVER EFFECTS*/
.container-img-projects {
  position: relative;
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 6px;
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  //background-color: #008CBA;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 6px;
}
.container-img-projects:hover .overlay {
  opacity: 1;
}
.text-hover {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
#container-text-projects {
  position: absolute;
  text-align: center;
  color: white;
  margin-top: 34%;
  width: 95%;
}

/*SHARE LIST*/
.content-share-list {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
}
.share-list {
  list-style: none;
  font-size: 14px;
}
.share-list li {
  float: left;
  margin-right: 15px;
}
.share-list i {
  margin-right: 7px;
}

/*CONTACT FORM CUSTOM CSS*/
.form-contact {
     width: 100%;
}
.form-contact input[type="text"], .form-contact input[type="email"], .form-contact input[type="tel"], .form-contact input[type="url"], textarea {
    font-size: 14px;
    line-height: 25px;
    background-color: #ffffff;
    border: none;
    border-bottom: 1px solid $color1;
    color: #000;
    width: 100%;
}
textarea {
	height: 120px;
}
.form-contact input[type="text"], .form-contact input[type="email"], .form-contact input[type="url"] {
    height: 40px;
}
.form-contact [type="submit"] {
	  background: -webkit-gradient(linear, left top, right top, from(#0de8e3), to(#27fcc4));
	  background: linear-gradient(to right, #0de8e3, #27fcc4);
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    padding: 8px 35px 8px 35px;
    border: none;
    width: 100%;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    float: right;
}
.form-contact [type="submit"]:hover {
    background: -webkit-gradient(linear, left top, right top, from(#0de8e3), to(#27fcc4));
    background: linear-gradient(to right, #0de8e3, #27fcc4);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}
.form-contact [type="submit"]:active {
    background: #000000;
}
#form-left, #form-right {
	width: 100%;
}
#content-contact-accept {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin-bottom: 25px;
}
#content-contact-accept label {
  margin-right: 8px;
}
.form-agencia {
  width: 100%;
}
.form-agencia input[type="text"], .form-agencia input[type="email"], .form-agencia input[type="url"] {
 font-size: 14px;
 line-height: 25px;
 background-color: #f8f8f8;
 border: none;
 border-bottom: 1px solid $color1;
 color: #000;
 width: 100%;
 height: 40px;
}
.form-agencia label {
  color: gray;
}
.form-agencia [type="submit"] {
  background: -webkit-gradient(linear, left top, right top, from(#0de8e3), to(#27fcc4));
  background: linear-gradient(to right, #0de8e3, #27fcc4);
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  padding: 8px 35px 8px 35px;
  border: none;
  width: 100%;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  float: right;
}
.form-agencia [type="submit"]:hover {
  background: -webkit-gradient(linear, left top, right top, from(#0de8e3), to(#27fcc4));
  background: linear-gradient(to right, #0de8e3, #27fcc4);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}
.form-agencia [type="submit"]:active {
  background: #000000;
}

.form-agencia-container {
  background-color:#f8f8f8;
  padding-bottom: 60px;
}

#content-agencia-accept label {
  margin-right: 8px;
}
#agencia_resident {
  margin-left: 8px;
  margin-top: 10px;
}
#agencia_profile, #agencia_cv {
  margin-left: 15px;
  margin-top: 10px;
}
.hr-contact {
  border: 1px solid #444444;
  background-color: #444444;
  width: 5%;
  margin-top: 35px;
}

/*PAGINA GRACIAS*/
.jumbotron {
  background-color: transparent;
}
.btn-primary {
  color: #fff;
  background-color: $color1;
  border-color: $color1;
}
.btn-primary:focus, .btn-primary:hover, .btn-primary:not([disabled]):not(.disabled):active {
  background-color: #494949;
  border-color: #494949;
  -webkit-box-shadow: 0 0 0 0.2rem rgb(72, 72, 72);
          box-shadow: 0 0 0 0.2rem rgb(72, 72, 72);
}


.hr-footer {
  border: solid 1px $color1;
  margin-bottom: 30px;
  background: $color1;
}
.text-next-page {
  font-size: 18px;
  font-weight: bold;
}
.hidden-md-down {
  display: none;
}
.hidden-lg-down {
  display: none;
}
.content-top-text {
  margin-top: 40px;
}
.content-share-list-top {
  margin-top: 20px; 
}
.content-next-page, .content-next-page-2 {
  border-top: solid 2px $color1;
  padding-top: 30px; 
  margin-top: 20px;   
}
p.title-service-text {
  font-size: 24px;
  color: white;
}

/*SERVICIOS*/
.content-metodologia {
  background-color: #f8f8f8;
  padding-bottom: 20px;
}
/*SERVICIOS IN*/
.col-padding-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.image-service {
  width: 100%;
  height: 100%;
}
img.img-service {
  width: 100%;
} 
.bg-gray {
  background-color: #f8f8f8; 
}
.text-services-bold {
  font-size: 20px;
  line-height: 29px;
  color: #444444;
  font-weight: 900;
}
.text-services {
  font-size: 18px;
  line-height: 29px;
  color: #444444;
}
.text-services-center {
  font-size: 18px;
  line-height: 29px;
  color: #444444;
  text-align: center;
}

/*APP*/
.border-left-green {
  border-bottom: 2px solid $color1;
  text-align: center;
}
.title-app {
  font-size: 25px;
  font-weight: 700;
  line-height: 39px;
  text-align: center;
}
.title-equipo-first {
  font-size: 20px;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
}
.title-equipo {
  font-size: 20px;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
  margin-top: 100px;
}
/*MARKETING*/
.title-marketing {
  font-size: 22px;
  font-weight: 700;
  line-height: 39px;
}

/*CASOS DE EXITO*/
.image-icon-project {
  width: 70%;
  height: auto;
}
#container-text-projects-2 {
  position: absolute;
  text-align: center;
  color: white;
  margin-top: 38%;
  width: 95%;
}
.custom-margin-projects {
  margin-top: 16% !important; 
}
p.title-service-text-2 {
  font-size: 30px;
  font-weight: 900;
  color: white;
  line-height: 0.6;
}
p.text-service-text-2 {
  font-size: 20px;
  font-weight: 500;
  color: white;
  line-height: 0.9;
}
/*PROYECTOS INDIVIDUALES*/
.content-img-project {
  padding-left: 0px;
  padding-right: 0px;
}
.image-project {
  width: 100%;
  height: 100%;
}
#content-text-project-info-left {
  padding-right: 35px;
}
#content-text-project-info-right {
  background-color: $color1;
  padding: 25px;
}
#content-text-project-info-right p{
  color: #ffffff;
}
#content-text-project-info-right-2 {
  background-color: #ffffff;
  padding: 0px 25px 25px 25px;
}
.text-project-info-bold, .text-project-info {
  font-size: 18px;
  line-height: 1.25;
  font-weight: 900;
}
.text-project-info-bold {
  font-size: 26px;
  line-height: 1.25;
}
#content-text-project-center {
  text-align: center;
}
#content-center {
  float: none;
  margin: 0 auto;
}
img.img-project {
  width: 100%;
} 

/*EQUIPO*/
.text-category-team {
  padding-top: 18px;
  font-size: 20px;
  font-weight: 900;
  margin: 0;
}
.line-category-team {
  margin-top: 0px;
}
.name-equip {
  padding-top: 15px;
  font-size: 20px;
  font-weight: 900;
  margin: 0;
  text-align: center;
}
.desc-equip {
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  text-align: center;
}
.desc-equip-2 {
  font-size: 16px;
  text-align: center;
}
.content-team-character {
}

#khalessi {
  overflow: hidden;
}
#khalessi img {
  width: 110%;
  margin-top: -55px;
}
.img-team-character {
  width: 90%;
}

/*AUDITORIA DE SEGURIDAD*/
.image-auditoria {
  width: 60%;
  height: 100%;
}




@import 'web/css/header.scss';
@import 'web/css/footer.scss';

@include media-breakpoint-up(sm) {
}
@include media-breakpoint-up(md) {

  .container-img-projects, .overlay {
    padding-right: 0px;
    padding-left: 6px;
  }
  .title-content-page-center {
    font-size: 33px;
  }
  .title-content-page-center, .text-content-page-center {
    text-align: left;
  }
  .text-sidebar-page-2-center {
    text-align: right;
  }
  .text-content-page {
    font-size: 16px;
  }

  .hidden-md-down {
    display: inherit;
  }
  .content-top-text, .content-share-list-top {
    margin-top: 50px;
    margin-bottom: 15px;
  }
  .share-list-inline {
    display: inline !important;
  } 
  .content-next-page-2 {
      border-top: 0px;
      padding-top: 0px; 
      margin-top: 0px;   
  }
  #container-text-projects, #container-text-projects-2 {
    width: 100%;
  }
  .share-list li {
    margin-right: 30px;
  }
  #content-contact-accept {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .wpcf7 input[type="submit"] {
    margin-top: 20px;
  }
  /*GLOBAL*/
  .col-padding-top-40 {
    padding-top: 40px;
  }
  .col-padding-top-30 {
    padding-top: 30px;
  }
  .col-padding-top-20 {
    padding-top: 20px;
  }
  .col-margin-top-100 {
    margin-top: 100px;
  }
  .col-margin-top-80 {
    margin-top: 80px;
  } 
  .col-margin-top-60 {
    margin-top: 60px;
  }
  .col-margin-top-40 {
    margin-top: 40px;
  }
  .col-margin-top-30 {
    margin-top: 30px;
  }
  .col-margin-top-20 {
    margin-top: 20px;
  }
  .col-margin-top-10 {
    margin-top: 10px;
  }
  /*NEW HOME*/
  .home-title-1 {
    font-size: 50px;
  }
  .home-title-2 {
    font-weight: 900;
    font-size: 46px;
  }
  .home-title-3, .home-title-3 a {
    font-size: 32px;
  }
  .home-title-4 {
    font-weight: 900;
    font-size: 46px;
    color: #ffffff;
  }
  .mt-home-title {
    margin-top: 35px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
  }
  p.home-text-1 {
    font-size:23px;
  }
  .bg-home-logos {
    background-image: url(../images/home/home-logos-bg.jpg);
    background-size: cover;
    background-position: center center;
  }
  hr.hr-home-4 {
    background-color: #ffffff;
    border-color: #ffffff;  
  }
  .back-home-image {
    height: 320px;
  }
  .home-text-2 {
    font-size: 20px;
  }
  .home-text-4 {
    font-size: 20px;
  }
  .col-padding-left-5 {
    padding-left: 15px;
  }
  .col-padding-right-5 {
    padding-right: 15px;
  }
  .col-padding-top-10 {
    padding-top: 0px;
  }
  .col-margin-bottom-40 {
    margin-bottom: 40px;
  }
  .col-margin-bottom-60 {
    margin-bottom: 60px;
  }
  .suscribe-input-submit {
    margin-top:0px;
  }

  /*AGENCY*/

  /*SERVICES*/
  .text-services-bold {
    font-size: 16px;
    line-height: 17px;
    color: #444444;
    font-weight: 900;
  }
  .text-services {
    font-size: 16px;
    line-height: 17px;
    color: #444444;
  }
  .text-services-bold-center {
    font-size: 16px;
    line-height: 17px;
    color: #444444;
    font-weight: 900;
    text-align: center;
  }
  .text-services-center {
    font-size: 16px;
  }

  /*APP*/
  .border-left-green {
    border-bottom: 0px; 
    border-left: 2px solid $color1; 
    text-align: left;
  }
  .title-app {
    text-align: right;
  }
  /*img carousel*/
  .infinite-carousel .list{
    width:1000px;
    height:200px;
    overflow:visible;
  }
  .infinite-carousel .viewport{
    width:800px;
    height:190px;
    overflow:hidden;
    margin: 0 auto;
  }
  .item{
    width:190px;
    height:190px;
    text-align: center;
    vertical-align: middle;
    line-height: 200px;
    margin-right:10px;
    float: left;
    /*display: inline-block;*/
  }
  span.pre, span.next {
    color: $color1;
    -webkit-transition: 0.5s; /* Safari */
    transition: 0.5s;
  }
  span.pre:hover, span.next:hover {
    color: #494949;
  }

  /* AUDITORIA DE SEGURIDAD */
  .text-auditoria-image {
    font-size: 22px;
  }
}
@include media-breakpoint-up(lg) {

	#form-left, #form-right {
		float:left;
		width: 50%;
	}

  #content-project-img {
    min-height: 400px;
  }

  #content-submit-buttom {
    width: 100%;
  }
  /*img carousel*/
  .infinite-carousel .list{
    width:1400px;
    height:220px;
  }
  .infinite-carousel .viewport{
    width:900px;
    height:220px;
  }
  .item{
    width:220px;
    height:220px;
  }
  .hidden-lg-down {
    display: inherit;
  }

  /*AGENCY*/
  .content-back-front {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    height: 600px;
  }
  .div-back {
    position: absolute; 
    left: 40px; 
    top: 40px;
    width: 55%;
  }
  .div-front {
    background-color: #ffffff;
    position: absolute; 
    left: 40%; 
    top: 90px;
    padding-top: 70px;
    padding-bottom: 60px;
    padding-left: 15px;
    padding-right: 15px;
    width: 60%;
  }
  .border-bottom-agency {
    border-bottom: 1px solid #444444;
    height: 60px;  
  }

  .mt-home-title {
    margin-top: 150px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px;
  }

}
@include media-breakpoint-up(xl) {}


@include media-breakpoint-down(md) {
  /*AUDITORIA DE SEGURIDAD*/
  .image-auditoria {
    width: 100%;
    height: 100%;
  }
}