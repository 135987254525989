$color1: #0de8e3;

#main-menu-container .navbar .container#main-menu-container {
    background-color: $background-color;
    height: auto;
}

#main-menu-container .navbar {
    padding: 0;
    background-color: white;
    font-size: 18px;
    height: 70px;

    .container {
        /*margin: 0;*/
        height: auto;
    }

    a {
        color: $link-color;
    }

    .navbar-brand {
        margin-left: 1em;
    }

    .close{
        display: none;
    }

    .navbar-nav {
        padding-top: 15px;
    }

    .navbar-nav > li > a {
        padding: 10px 15px;
        text-transform: uppercase;
        border-top: 1px solid $menu-border-color;
    }

    .navbar-nav > li:last-child > a {
        border-bottom: 1px solid $menu-border-color;
    }

    .dropdown-toggle::after {
        float: right;
        top: 9px;
        position: relative;
        font-size: 25px;
    }

    .navbar-nav > li.show .dropdown-toggle::after {
        transform: rotate(180deg);
    }

    .dropdown-menu {
        border: 0;
        font-size: 18px;
        margin-top: 0px;
        left: auto;
    }

    .active {
        background-color: $background-color;
        height: 72px;
    }

    .navbar-toggler{

        /*padding: 0;
        margin-right: 1em;
        border: 0;*/

        .open{
            display: none;
        }
        .close{
            display: block;
        }

        &.collapsed{
            .open{
                display: block;
            }
            .close{
                display: none;
            }
        }
    }

    ul.langs {
        margin-top: 74px;
    }

    ul.langs > li > a {
        display: none;
    }

    ul.langs > li > div {
        padding-left: 15px;
        padding-bottom: 30px;

        a {
            width: auto;
            display: inline-block;
            padding: 0;
            margin-right: 15px;

            &.active {
                font-weight: bold;
            }
        }
    }

}

#content-header-img, #content-header-img-home {
    background-color: rgba(0, 0, 0, 0.54);
    height: 220px;
    background-size: cover;
    background-position: center center;
}
#content-header-img-home {
    height: 420px;
    background-position: center center;
}
.back-image-home {
    background-image: url(../images/home/home-mobile.png);
}
#layout-header {
    background-color: rgba(0, 0, 0, 0.54);
    height: 220px;
}
.title-page-header {
    width: 291px;
    height: 106px;
    font-family: Lato;
    font-size: 33px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fffdfd;
}
#content-header-title {
    padding-top: 70px;
}
#content-breadcrumb {
    padding-top: 17px;
    height: 50px;
}
.fa-lg {
    font-size: 1.6em;
}

#main-menu-container .top-menu-nav {
    height: 35px;
    padding: 0 15px;

    ul.top-menu-links {
        width: 100%;
        text-align: right;
        margin-bottom: 0px;
    }

    ul.top-menu-links li:not(:first-child) a {
        color: $color1;
    }
}

#app-color {
    color: $color1;
}



@include media-breakpoint-up(sm) {}
@include media-breakpoint-up(md) {
    .back-image-home {
        background-image: url(../images/home/home.jpg);
    }
    #content-header-img, #layout-header, #content-header-img-home {
        height: 600px;  
    }
    #content-header-title {
        padding-top: 150px;
    }
    #content-icon-home {
        padding-top: 210px;
    }
}
@include media-breakpoint-up(lg) {
    #main-menu-container .navbar {
        font-size: 14px;

        .navbar-nav {
            padding-top: 0;
        }

        .navbar-nav > li > a {
            /*padding: 30px 15px;*/
        }

        .dropdown-toggle::after {
            left: 2px;
            font-size: 14px;
        }

        ul.langs {
            margin-top: 0px;
        }
        ul.langs > li > a {
            display: block;
        }

        ul.langs > li > div {
            padding: 20px;
            margin-top: -5px;
            font-size: 14px;
            z-index: 1001;

            a {
                width: 100%;
                display: block;
                padding: 0;
                margin-right: auto;
                line-height: 25px;

                &.active {
                    font-weight: bold;
                }
            }
        }

        .navbar-nav > li > a {
            border-top: 0px;
        }

        .navbar-nav > li:last-child > a {
            border-bottom: 0;
        }

        .dropdown-item.active,
        .dropdown-item:active,
        .dropdown-item:focus {
           background-color: transparent;
           color: $link-color; 
        }

        .dropdown-menu a:hover {
            text-decoration: none;
            color: $link-color-hover;
            background-color: transparent;
        }

        .dropdown-menu a {
            padding: 0;
            color: #000000;
        }

        .dropdown-menu a:hover {
            text-decoration: none;
            color: $link-color-hover;
            background-color: transparent;
        }

        .dropdown-menu .subgroup > a:hover,
        .dropdown-menu .subgroup .dropdown-menu a:hover {
            color: $link-color-hover;
            background-color: transparent;
        }
    }


    .wp-main-nav .dropdown {
        position: inherit;
    }

    .wp-main-nav > li > div {
            position: relative;


        .dropdown-item { 
            text-align: center;

            .dropdown-item { 
                width: 100%;
            }
        }

        .dropdown-menu {
            display: block;
            width: 100%;
        }
    }

    #menu-dropdown-full {
        position: fixed;
        top: 100px;
        width: 100%;
        z-index: 1002;
        background-color: $background-color;
        overflow: hidden;
        padding-top: 25px;
        padding-bottom: 45px;
        border-bottom: 1px solid #ccc;
        box-shadow: 0px 9px 48px -30px #888888;

        .dropdown-item.active,
        .dropdown-item:active,
        .dropdown-item:focus {
           background-color: transparent;
           color: $link-color; 
        }

        .dropdown-menu {
            display: block;
            width: 100%;
            position: relative;
            border: 0;
            margin: 0;
            padding: 0;
        }

        .dropdown-menu a {
            padding: 0;
        }

        .dropdown-menu a:hover {
            text-decoration: none;
            color: $link-color-hover;
        }

        .dropdown-menu .subgroup {
            width: 25%;
            float: left;
            font-size: 18px;
        }

        .dropdown-menu .subgroup > a {
            margin-bottom: 30px;
            color: #000000;
        }

        .dropdown-menu .subgroup .dropdown-menu a {
            display: block;
            font-size: 14px;
            color: $link-color;
            line-height: 25px;
        }

        .dropdown-menu .subgroup > a:hover,
        .dropdown-menu .subgroup .dropdown-menu a:hover {
            color: $link-color-hover;
            background-color: transparent;
        }
    }

    #content-header-img, #layout-header {
        height: 500px;
    }
    .title-page-header {
        width: 634px;
        height: 70px;
        font-size: 62px;
        line-height:  1.13;
    }
    #content-header-img-home {
        height: 600px;
    }
    .title-page-header-auditoria {
        width: 665px !important;
    }

}

@include media-breakpoint-up(xl) {
    #content-header-img-home {
        height: 700px;
    }
} 

@include media-breakpoint-down(md) {
    ul.top-menu-links {
        font-size: 14px;
    }
    ul.top-menu-links li {
        margin-right: 15px;
    }
}