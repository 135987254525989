footer {

    border-top: 2px solid $color1;
    padding-top: 50px;
    background-color: $background-color;
    margin-top: 20px;

    .title {
        text-transform: uppercase;
        font-size: 14px;
        color: $link-color;
    }

}

.adegi-sm {
    background-image: url('../images/footer/adegi.jpg');
    width: 257px;
    height: 84px;
    margin: 0 auto;
}

.contacto {
    font-size: 18px;

    li {
        margin-bottom: 30px;
    }

    li:before {
        width: 23px;
        height: 20px;
        margin-right: 17px;
        display: inline-block;
        vertical-align: middle;
        content: "";
        background-position: center;
        background-size: 23px 20px;
    }

    li.phone:before {
        background-image: url('../images/footer/icon-phone.svg');
    }

    li.email:before {
        background-image: url('../images/footer/icon-email.svg');
    }
}

.rrss {
    text-align: center;

    li {
        display: inline-block;
        margin-right: 35px;
        background-position: center;
        background-repeat: no-repeat;

        &:last-child {
            margin-right: 0;
        }

        &.facebook {
            background-image: url('../images/footer/icon-facebook.svg');
        }
        
        &.linkedin {
            background-image: url('../images/footer/icon-linkedin.svg');
        }
        
        &.twitter {
            background-image: url('../images/footer/icon-twitter.svg');
        }
        
        &.instagram {
            background-image: url('../images/footer/icon-instagram.svg');
        }

        a {
            display: block;
            width: 24px;
            height: 24px;
        }

        span {
            display: none;
        }
    }
}

.footer_top {

    .gugo-logo {
        width: 130px;
        height: 69px;
        background-image: url('../images/footer/logo.svg');
        background-repeat: no-repeat;
        margin: 0 auto 40px auto;
        background-size: 100%;
    }

    ul {
        padding-left: 0;
    }

    li {
        list-style: none;
    }

    .title {
        margin-bottom: 25px;
    }

    .menu li {
        margin-bottom: 25px;
    }
    
}

.footer_bottom_1 {
    font-size: 14px;

    ul {
        padding: 0;
    }

    li {
        margin: 30px 0;
        list-style: none;
    }
}

// aviso legal
.footer_bottom_2 {
    font-size: 11px;
    margin-bottom: 20px;

    ul {
        text-align: center;
        padding: 0;
    }

    li {
        display: inline-block;
    }

    li:after {
        content: " | ";
        margin: 0 5px;
    }

    li:last-child:after {
        display: none;
    }

    a {
        color: #000;
    }
}

@include media-breakpoint-up(lg) {
    .adegi-sm {
        display: none;
    }

    footer {
        font-size: 14px;
    }

    .footer_top {
        .gugo-logo {
            margin: 0;
        }

        .adegi-logo {
            background-image: url('../images/footer/adegi.jpg');
            width: 257px;
            height: 84px;
            margin: 40px 0 30px -20px;
        }

        .title {
            margin-bottom: 15px;
        }

        .menu li {
            margin-bottom: 15px;
        }
    }
    .rrss {
        text-align: inherit;

        li {
            margin-right: 15px;
        }
    }

    .contacto {
        font-size: 14px;

        li {
            margin-bottom: 15px;
        }

        li:before {
            width: 12px;
            height: 12px;
            margin-right: 12px;
            background-size: 12px 12px;
        }
    }

    // aviso legal
    .footer_bottom_2 {
        ul {
            text-align: right;
        }
    }

    .footer_bottom_1{
         li {
            display: inline-block;
            margin: 15px 0;
        }

        li:after {
            content: " | ";
            margin: 0 5px;
        }

        li:last-child:after {
            content: "";
        }
    }
}

.rrss li a{
    color: $color1;
}

@include media-breakpoint-up(lg) {}
@include media-breakpoint-up(xl) {}